import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(5)
    }
    render() {

        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>系统架构图及简要说明</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>简要说明</span><div className={styles.content_text}>暂无</div>
                </div>
                <p className={styles.part_title}>实验教学项目</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>计算机操作系统和版本要求：</span><div className={styles.content_text}>无</div>
                    <span className={styles.content_title}>其他计算终端操作系统和版本要求：</span><div className={styles.content_text}>Pico Neo2 VR一体式头戴显示设备</div>
                    <span className={styles.content_title}>支持移动端：</span><div className={styles.content_text}>是</div>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)