import React, { Component } from 'react'
import { Table } from 'antd';
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(2)
    }
    render() {

        const columns = [
            {
                title: '序号',
                align: 'center',
                key: 'key',
                dataIndex: 'key',
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            },

            {
                title: '所在单位',
                dataIndex: 'address',
                key: 'address',
                align: 'center',
            },
            {
                title: '专业技术职务',
                key: 'tags',
                dataIndex: 'tags',
                align: 'center',

            },
            {
                title: '所属学科',
                key: 'char',
                align: 'center',
                dataIndex: 'char',
            },
            {
                title: '承担任务',
                key: 'session',
                align: 'center',
                dataIndex: 'session',
            },

        ];

        const datasource = [
            {
                key: '1',
                name: '蔡霞',
                address: '附属口腔医院',
                tags: '副主任医师',
                char: '口腔医学',
                session: '内容设计'
            },
            {
                key: '2',
                name: '陈飞',
                address: '附属口腔医院',
                tags: '主治医师',
                char: '口腔医学',
                session: '数据收集整理'
            },
            {
                key: '3',
                name: '丁佩惠',
                address: '附属口腔医院',
                tags: '副主任医师',
                char: '口腔医学',
                session: '脚本撰写'
            },
            {
                key: '4',
                name: '戚刚刚',
                address: '附属口腔医院',
                tags: '主治医师',
                char: '口腔医学',
                session: '虚拟教学'
            },
            {
                key: '5',
                name: '曹桂芬',
                address: '附属口腔医院',
                tags: '主治医师',
                char: '口腔医学',
                session: '人员协调管理'
            },
            {
                key: '6',
                name: '鲍冰鹏',
                address: '附属口腔医院',
                tags: '主治医师',
                char: '口腔医学',
                session: '视频录制'
            },
            {
                key: '7',
                name: '方文',
                address: '附属口腔医院',
                tags: '主治医师',
                char: '口腔医学',
                session: '虚拟教学'
            },
        ];
        const datasourcesub = [
            {
                key: '1',
                name: '刘勇',
                address: '医视界医疗科技发展（无锡）有限责任公司',
                tags: '技术员',
                char: '三维图像',
                session: '模型编辑优化'
            },
            {
                key: '2',
                name: '岳杨阳',
                address: '医视界医疗科技发展（无锡）有限责任公司',
                tags: '技术员',
                char: 'AR&VR',
                session: '交互实现'
            },
            {
                key: '3',
                name: '赵一阳',
                address: '医视界医疗科技发展（无锡）有限责任公司',
                tags: '技术员',
                char: '心理管理',
                session: '软件推广'
            },
        ]

        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>实验教学项目负责人情况</p>
                {/* "table table-bordered part-table" */}
                {/* <table className={styles.part_table} cellPadding='0' cellSpacing='0' border={1}>
                    <tbody >
                        <tr>
                            <th>姓名</th><td>李晓军</td>
                            <th>性别</th><td>/</td>
                            <th>出生年月</th><td>/</td>
                        </tr>
                        <tr>
                            <th>学历</th><td>博士</td>
                            <th>学位</th><td>博士</td>
                            <th>专业技术职务</th><td>牙周科主任、主任医师</td>
                        </tr>
                        <tr>
                            <th>行政职务</th><td>浙江大学医学院附属口腔医院党委委员</td>
                            <th>院系</th><td colSpan="3">/</td>
                        </tr>
                    </tbody>
                </table> */}
                <div className={styles.part_content}>
                    <div className={styles.intro} style={{ textIndent: '29px' }}>
                        <p>负责人：李晓军</p>
                        <p>浙江大学医学院附属口腔医院党委委员、牙周科主任、主任医师、博士</p>
                        <p>全国高职高专口腔医学专业教材建设评审委员会  委员</p>
                        <p>人民卫生出版社高职高专规划教材《口腔内科学》联合主编、《中国口腔医学文化博览》副主编</p>
                        <p>中华口腔医学会牙周病学专业委员会  委员</p>
                        <p>浙江省口腔医学会牙周病学专业委员会  副主任委员</p>
                        <p>著有《牙医史话——中国口腔卫生文史概览》，参编《临床疾病与免疫》、《牙周病临床诊治要点》、《牙科博览》等</p>
                        <p>长期从事《牙周病学》教学</p>
                        <p>浙江大学医学院附属口腔医院牙周科住院医师规范化培训  教学主任</p>
                        <p>2017年浙江省住院医师规范化培训高级师资班  优秀导师</p>
                        <p>2018年 全国住院医师规范化培训  优秀带教老师</p>
                    </div>
                </div>
                {/* <p className={styles.part_title}>教学研究情况</p>
                <div className={styles.part_content}>
                    <p className={styles.fangsong}>（主持的教学研究课题（含课题名称、来源、年限，不超过5项）；作为第一署名人在国内外公开发行的刊物上发表的教学研究论文（含题目、刊物名称、时间，不超过10项）；获得的教学表彰/奖励（不超过5项））</p>
                    <p style={{ textIndent: '29px', lineHeight: '120%' }}>
                        <span >待填写</span>
                    </p>
                </div>
                <p className={styles.part_title}>学术研究情况</p>
                <div className={styles.part_content}>
                    <p className={styles.fangsong}>（近五年来承担的学术研究课题（含课题名称、来源、年限、本人所起作用，不超过5项）；在国内外公开发行刊物上发表的学术论文（含题目、刊物名称、署名次序与时间，不超过5项）；获得的学术研究表彰/奖励（含奖项名称、授予单位、署名次序、时间，不超过5项））</p>
                    <p style={{ textIndent: '29px', lineHeight: '120%' }}>
                        <span >待填写</span>
                    </p>
                </div> */}
                <p className={styles.part_title}>实验教学项目教学服务团队情况</p>
                <Table title={() => '团队主要成员（含负责人）'} className={styles.ant_table} size="middle" bordered columns={columns} dataSource={datasource} />
                <Table title={() => '团队其他成员'} className={styles.ant_table} size="middle" bordered columns={columns} dataSource={datasourcesub} />
                <p className={styles.part_tips}>
                    项目团队总人数：
                    <span className={styles.teamPeoTotal}>10</span>人<i style={{ margin: '0 8px' }}>
                    </i>高校人员数量：<span className={styles.schoolPeoNum}>7</span>人<i style={{ margin: '0 8px' }}>
                    </i>企业人员数量：<span className={styles.societyPeoNum}>3</span>
                    人
                </p>
                <p className={styles.fangsong}>注：1.教学服务团队成员所在单位需如实填写，可与负责人不在同一单位。</p>
                <p className={styles.fangsong} style={{ marginLeft: "2em" }}>2.教学服务团队须有在线教学服务人员和技术支持人员，请在备注中说明。</p>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)