import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(7)
    }
    render() {
        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>服务计划</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>按视频所示完善即可</span>
                    {/* <div className={styles.content_text}>
                        <p className={styles.content_text_sub}>
                        按视频所示完善即可
                    </p>
                    </div> */}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)