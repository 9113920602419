import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(6)
    }
    render() {
        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>实验教学项目特色</p>
                <div className={styles.part_content}>
                    <p className={styles.fangsong}>（体现虚拟仿真实验项目建设的必要性及先进性、教学方式方法、评价体系及对传统教学的延伸与拓展等方面的特色情况介绍）</p>
                    <span className={styles.content_title}>实验方案设计思路：</span>
                    <div className={styles.content_text}>
                        <p className={styles.content_text_sub}>
                            医学图像分割在《医学图像处理》专业课程中为重点教学内容，其中分割算法是教学中的难点。本文设计了一款用户交互界面的医学图像分割虚拟仿真系统。其简单易懂的界面以及符合理论的处理结果，对于刚刚入门的新人来说，学习起来更得心应手。该系统具有良好的实用性、用户交互性。用户可以形象直观地感受到图像分割算法得到的效果以及算法的具体实现步骤，并且该系统具有一定的扩展和改进空间。
                            医学图像分割在《医学图像处理》专业课程中为重点教学内容，其中分割算法是教学中的难点。本文设计了一款用户交互界面的医学图像分割虚拟仿真系统。其简单易懂的界面以及符合理论的处理结果，对于刚刚入门的新人来说，学习起来更得心应手。该系统具有良好的实用性、用户交互性。用户可以形象直观地感受到图像分割算法得到的效果以及算法的具体实现步骤，并且该系统具有一定的扩展和改进空间。
                    </p>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)