import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(8)
    }
    /* <p className={styles.content_text_sub}>
     特色与创新资源项目建设和更新：建设具有专业特色对虚拟仿真实验项目，满足教师和学生对于创新型实验的设计需求.
     </p> */
    render() {
        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>知识产权</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>软件著作权登记情况：</span>
                    <div className={styles.content_text}>
                        未登记
                    </div>
                    <span className={styles.content_title}>软件名称：</span>
                    <div className={styles.content_text}>
                        暂无内容
                    </div>
                    <span className={styles.content_title}>是否与项目名称一致：</span>
                    <div className={styles.content_text}>
                        暂无内容
                    </div>
                    <span className={styles.content_title}>著作权人：</span>
                    <div className={styles.content_text}>
                        暂无内容
                    </div>
                    <span className={styles.content_title}>权利范围：</span>
                    <div className={styles.content_text}>
                        暂无内容
                    </div>
                    <span className={styles.content_title}>登记号：</span>
                    <div className={styles.content_text}>
                        暂无内容
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)