
/**
 * @name Router
 * @desc 页面路由配置
 */

// 导入库
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
// 导入框架
import Frame from "../component/layout.js";

// 导入组件
import Home from '../pages/Home'
import Intro from '../pages/Intro'
import Net from '../pages/Net'
import Plan from '../pages/Plan'
import Prop from '../pages/Prop'
import Scrb from '../pages/Scrb'
import Spec from '../pages/Spec'
import Struc from '../pages/Struc'


class ReactRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/">
          <Redirect to="/scrb" />
            <Frame>
              <Switch>
                {/* 用户列表 */}
                <Route path="/home" exact component={Home}></Route>
                <Route path="/intro" exact component={Intro}></Route>
                <Route path="/Net" exact component={Net}></Route>
                <Route path="/Plan" exact component={Plan}></Route>
                <Route path="/Prop" exact component={Prop}></Route>
                <Route path="/Scrb" exact component={Scrb}></Route>
                <Route path="/Spec" exact component={Spec}></Route>
                <Route path="/Struc" exact component={Struc}></Route>
                {/* <Route path="/admin/setting" exact component={Setting}></Route>
                <Route
                  path="/admin/dashboard"
                  exact
                  component={Dashboard}
                ></Route>
                <Route path="/admin/article" exact component={Article}></Route>
                <Route path="/admin/users" exact component={Users}></Route>
                <Route path="/admin/roles" exact component={Rouls}></Route>
                <Route
                  path="/admin/roles/create"
                  exact
                  component={Create}
                ></Route>
                <Route path="/goods/list" exact component={Goods}></Route>
                <Route
                  path="/admin/ordersAdmin"
                  exact
                  component={OrdersAdmin}
                ></Route>
                <Route path="/admin/charts" exact component={Charts}></Route>
                <Route
                  path="/admin/category"
                  exact
                  component={Category}
                ></Route> */}
              </Switch>
            </Frame>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default ReactRouter;

