// 导入模块
import { combineReducers } from "redux-immutable";

// 导入reducer
// import {reducer as 模块名Reducer}from '../pages/模块名/store';
import { reducer as HomeReducer } from "../pages/Home/store/index";
// 合并reducer
const reducers = combineReducers({
  // 模块小驼峰名:模块名Reducer大驼峰,
  home: HomeReducer,
});

// 导出
export default reducers;
