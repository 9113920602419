import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(4)
    }
    render() {

        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>网络条件要求</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>客户端到服务器带宽要求：</span><div className={styles.content_text}>外网带宽100M</div>
                    <span className={styles.content_title}>同时支持在线人数：</span><div className={styles.content_text}>1000</div>
                </div>
                <p className={styles.part_title}>用户操作系统要求（如Window、Unix、IOS、Android等）</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>计算机操作系统和版本要求：</span><div className={styles.content_text}>无</div>
                    <span className={styles.content_title}>其他计算终端操作系统和版本要求：</span><div className={styles.content_text}>Pico Neo2 VR一体式头戴显示设备</div>
                    <span className={styles.content_title}>支持移动端：</span><div className={styles.content_text}>是</div>
                </div>
                <p className={styles.part_title}>用户非操作系统软件配置要求（如浏览器、特定软件等）</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>需要特定插件：</span><div className={styles.content_text}>无</div>
                    <span className={styles.content_title}>其他计算终端非操作系统软件配置要求：</span><div className={styles.content_text}>提供源安装文件（APK格式）与说明文档</div>

                </div>
                <p className={styles.part_title}>用户硬件配置要求（如主频、内存、显存、存储容量等）</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>计算机硬件配置要求：</span><div className={styles.content_text}>无</div>
                    <span className={styles.content_title}>其他计算终端硬件配置要求：</span><div className={styles.content_text}>Pico Neo2 VR一体式头戴显示设备</div>
                </div>
                <p className={styles.part_title}>用户特殊外置硬件要求（如可穿戴设备等）</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}> 计算终端特殊位置硬件要求：</span><div className={styles.content_text}>无</div>
                </div>
                <p className={styles.part_title}>网络安全</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>项目系统是否完成国家信息安全等级保护：</span><div className={styles.content_text}>无</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { 
        // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { 
        // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)