import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(3)
    }
    render() {

        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>项目介绍</p>
                <div className={styles.part_content}>
                    <div className={styles.srcb}>
                        <p>
                            <span className={styles.content_title}>1.</span><span className={styles.content_text}>虚拟仿真龈下刮治实验平台以交互式虚拟仿真教学为手段、以疾病的治疗为核心，建设包括牙周解剖生理、病理生理、临床诊治、效果评估及疾病转归等内容的虚拟仿真教学体系，以实现实验室操作与临床思维构建的有机结合。</span></p>
                        <p>
                            <span className={styles.content_title}>2.</span><span className={styles.content_text}>利用虚拟仿真技术及多媒体的图、文、声、像技术结合，提高学生学习的积极性和主动性，缩短临床实习的适应期。</span></p>
                        <p>
                            <span className={styles.content_title}>3.</span><span className={styles.content_text}>利用虚拟仿真龈下刮治实验平台，学生通过线上线下理论学习，仿头模和虚拟仿真操作实训，构建线上线下相结合的一体化的牙周病学课程体系。</span>
                        </p>
                        <p>
                            <span className={styles.content_title}>4.</span><span className={styles.content_text}>利用虚拟仿真实验平台的学生在操作过程中的智能纠错提示，训练完成后可进行录像和生成成绩，达到实时交互反馈的效果。完全改变既往教师认为主观的评价方式，达到公平、公正、客观与迅速的评价目的；由此，也便于教师及时发现学生的理论及操作的薄弱环节，进行动态跟踪和有针对性的指导。</span>
                        </p>
                        <p>
                            <span className={styles.content_title}>5.</span><span className={styles.content_text}>基于虚拟仿真实验平台搭建的口腔临床操作环境，为学生提供丰富的实践经验，具有可重复性、操作反馈等优势，节约培训时间和费用，弥补仿头模等传统技能训练途径的不足，在模拟操作过程中也可以避免锐利的手用刮治器损伤学生等安全隐患。</span>
                        </p>
                    </div>


                </div>
                <p className={styles.part_title}>实验课时</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>实验所属课程所占课时：</span><div className={styles.content_text}>12</div>
                    <span className={styles.content_title}>该实验项目所占课时：</span><div className={styles.content_text}>2</div>
                </div>
                <p className={styles.part_title}>实验知识点</p>
                <div className={styles.part_content}>
                    <div className={styles.content_text}>龈下刮治器械的特点；牙周袋的病理；牙周探诊的方法；上下颌前后牙根面的解剖生理特点；不同区域龈下刮治的体位；不同区域龈下刮治器械的到达与贴合、刮治的方向；根面平整效果的检查与评价；口腔卫生宣教。</div>

                </div>
                <p className={styles.part_title}>实验仪器设备（装置或软件等）</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>装置：</span><div className={styles.content_text}>Pico Neo2 VR一体机</div>
                    <span className={styles.content_title}>软件:</span><div className={styles.content_text}>浙江大学口腔医学院牙周检查及刮治虚拟仿真实验平台</div>

                </div>
                <p className={styles.part_title}>实验结果与结论要求</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>是否记录每步实验结果：</span><div className={styles.content_text}>无</div>
                    <span className={styles.content_title}>实验结果与结论要求：</span><div className={styles.content_text}>实验报告</div>
                    <span className={styles.content_title}>其他描述:</span><div className={styles.content_text}>无</div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)