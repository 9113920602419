
import React, { Component, } from 'react'
import { Tree, Row, Col } from 'antd';
import styles from './index.module.scss'
import { connect } from 'react-redux';
import {
    changeCom,
    changePath
} from "./store/actionCreators";
import Myvideo from '../../component/video.js'
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treeData: [
                { title: '002_观察器械', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/2.mp4', key: '1' },
                { title: '003_观察牙区', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/3.mp4', key: '2' },
                { title: '004_前牙区探诊终点位', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/4.mp4', key: '3' },
                { title: '005_前牙区探诊患者头部选择', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/5.mp4', key: '4' },
                { title: '006_拿起器械开始前牙探诊', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/6.mp4', key: '5' },
                { title: '007_右下颌后牙探诊终点位', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/7.mp4', key: '6' },
                { title: '008_右下颌后牙探诊患者头部', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/8.mp4', key: '7' },
                { title: '009_拿起器械开始右下颌后牙探诊', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/9.mp4', key: '8' },
                { title: '010_前牙刮治终点位选择', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/10.mp4', key: '9' },
                { title: '011_前牙刮治患者头部选择', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/11.mp4', key: '10' },
                { title: '012_拿起器械开始前牙刮治', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/12.mp4', key: '11' },
                { title: '013_右下颌后牙刮治终点位', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/13.mp4', key: '12' },
                { title: '014_右下颌后牙刮治患者头部', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/14.mp4', key: '13' },
                { title: '015_拿起器械开始后牙刮治教学', content: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj/15.mp4', key: '14' },
            ]
        }

    }
    componentDidMount() {
        this.props.changeCom(1)
        // this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state, prevState) {
        console.log(state)
    }
    handleTabChange(activeKey) {
        console.log(activeKey)
    }
    onSelect = (selectedKeys, info) => {
        const { treeData } = this.state
        this.props.changePath(treeData[selectedKeys - 1].content)
    };

    onCheck(checkedKeys, info) {
        console.log('onCheck', checkedKeys, info);
    }
    render() {
        const { treeData } = this.state
        return (
            <div className={styles.home}>
                {/* <Tabs tabPosition='right' onChange={() => this.handleTabChange()}>
                    {panes.map(pane => 
                        <TabPane tab={pane.title} key={pane.key}>
                        <Myvideo key={Math.random()} path={pane.content}/>
                        </TabPane>
                    )}

                </Tabs> */}
                <Row>
                    <Col span={18} >
                        <Myvideo className={styles.list_content} key={Math.random()} path={this.props.path} />
                    </Col>
                    <Col span={6} >

                        <Tree
                            className={styles.list_menu}
                            defaultSelectedKeys={['1']}
                            onSelect={this.onSelect}
                            treeData={treeData}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected,
        path: state.toJS().home.path
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val)),
        changePath: val => dispatch(changePath(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)