import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../pages/Home/store/actionCreators";
import {
    Player,
    ControlBar,
    PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton,
    BigPlayButton,
    LoadingSpinner
} from 'video-react';
import "../../node_modules/video-react/dist/video-react.css"; // import css
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            start: 0

        }
    }
    load() {
        this.player.load();
      }
    render() {
        
        return (
            <Fragment>
                <div>
                    <Player
                        ref={c => {
                            this.player = c;
                        }}
                       
                    >
                        <source
                            src={this.props.path}
                            type="video/mp4"
                        />
                        <BigPlayButton position="center" />
                        <LoadingSpinner />
                        <ControlBar autoHide={false} disableDefaultControls={false}>
                            <ReplayControl seconds={10} order={1.1} />
                            <ForwardControl seconds={30} order={1.2} />
                            <PlayToggle />
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
                            <VolumeMenuButton />
                        </ControlBar>
                    </Player>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)